import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

import { Pagination, Autoplay, Keyboard } from 'swiper'

const Gallery = ({ title, list }) => {
  return (
    <>
      <h2 className="text-center text-4xl font-bold mt-8 mb-6">{title}</h2>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        keyboard={{
          enabled: true,
          onlyInViewport: false,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay, Keyboard]}
        className="swiper"
        breakpoints={{
          // when window width is higher than 499px
          499: {
            slidesPerView: 3,
            spaceBetweenSlides: 30,
          },
        }}
      >
        {list.map(({ photo, price, title }, index) => (
          <SwiperSlide key={index}>
            <div className="swiper-slide__block">
              {photo.url && (
                <div className="best-of-img mb-3">
                  <img className="swiper-slide__img" src={photo.url} />
                </div>
              )}
              <h3 className="font-bold best-of-img-title">{title}</h3>
              <p>{price}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default Gallery
