import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Parallax } from 'react-parallax'

const Section = ({ data, index, length }) => {
  const isFirst = index === 0
  const isLast = index + 1 === length

  const img = data.background?.url.length > 0 ? data.background?.url : ''

  return (
    <Parallax
      className={`${isFirst ? 'bottom-parallax-block' : ''} mb-2`}
      bgImage={img}
      strength={400}
    >
      <div className={`grid grid-cols-12 container desktop:m-auto py-6`}>
        <div
          className={`${
            isFirst
              ? 'col-start-1 col-end-13 laptop:col-end-8 desktop:mt-8 desktop:col-end-7 text-center py-10'
              : 'col-start-1 laptop:col-start-2 col-end-13 laptop:col-end-12'
          }`}
        >
          <ReactMarkdown
            className={`mb-2 ${
              isFirst
                ? 'bg-black bg-opacity-40 text-white p-3 laptop:px-5 laptop:py-3 text-2xl'
                : 'title-stamp bg-white font-bold p-2 text-center tablet:text-left px-6 text-xl'
            }`}
          >
            {data.title}
          </ReactMarkdown>
          {data.subtitle && (
            <ReactMarkdown
              className={`${
                isFirst
                  ? 'bg-black bg-opacity-40 text-white p-3 laptop:px-5 laptop:py-3 text-2xl'
                  : 'bg-white'
              }`}
            >
              {data.subtitle}
            </ReactMarkdown>
          )}
          {data.content && (
            <ReactMarkdown
              parserOptions={{ commonmark: true }}
              className={`bg-white px-2 py-5 ${isLast ? 'border-t' : ''}`}
            >
              {data.content}
            </ReactMarkdown>
          )}
        </div>
      </div>
    </Parallax>
  )
}

export default Section
